require('./bootstrap');

import {createApp, defineAsyncComponent} from 'vue';
import mitt from "mitt";
import {ZiggyVue} from "./plugins/ziggyVue";
import {Ziggy} from "./ziggy";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import * as Sentry from "@sentry/vue";


// Create app
const app = createApp({});

Sentry.init({
    app,
    dsn: process.env.MIX_SENTRY_DSN_PUBLIC,
});

app.provide('emitter', mitt());

app.use(ZiggyVue, Ziggy);

app.component(
    "infinite-loading",
    InfiniteLoading
);

app.component(
    'example-component',
    defineAsyncComponent(() => import('./components/ExampleComponent'))
);

app.component(
    'mobile-responsive-search-button-component',
    defineAsyncComponent(() => import('./components/MobileResponsiveSearchButtonComponent'))
);

app.component(
    'mobile-responsive-search-component',
    defineAsyncComponent(() => import('./components/MobileResponsiveSearchComponent'))
);

app.component(
    'mobile-settings-button-component',
    defineAsyncComponent(() => import('./components/MobileSettingsButtonComponent'))
);

app.component(
    'search-input-component',
    defineAsyncComponent(() => import('./components/SearchInputComponent'))
);

app.component(
    'mobile-responsive-profile-button-component',
    defineAsyncComponent(() => import('./components/MobileResponsiveProfileButtonComponent'))
);

app.component(
    'suggestion-users-component',
    defineAsyncComponent(() => import('./components/SuggestionUsersComponent'))
);

app.component(
    'all-users-component',
    defineAsyncComponent(() => import('./components/AllUsersComponent'))
);

app.component(
    'feed-component',
    defineAsyncComponent(() => import('./components/FeedComponent'))
);

app.component(
    'post-settings-button-component',
    defineAsyncComponent(() => import('./components/PostSettingsButtonComponent'))
);

app.component(
    'profile-posts-component',
    defineAsyncComponent(() => import('./components/ProfilePostsComponent'))
);

app.component(
    'toastr-bottom-component',
    defineAsyncComponent(() => import('./components/ToastrBottomComponent'))
);

app.component(
    'follow-up-comment-component',
    defineAsyncComponent(() => import('./components/FollowUpCommentComponent'))
);

app.component(
    'notifications-component',
    defineAsyncComponent(() => import('./components/NotificationsComponent'))
);

app.component(
    'notifications-icon-component',
    defineAsyncComponent(() => import('./components/NotificationsIconComponent'))
);

app.component(
    'welcome-modal-component',
    defineAsyncComponent(() => import('./components/WelcomeModalComponent'))
);

app.component(
    'welcome-modal-show-btn-component',
    defineAsyncComponent(() => import('./components/WelcomeModalShowBtnComponent'))
);

app.component(
    'search-page-component',
    defineAsyncComponent(() => import('./components/SearchPageComponent'))
);

app.component(
    'whats-new-page-component',
    defineAsyncComponent(() => import('./components/WhatsNewPageComponent'))
);

app.component(
    'bug-submission-modal-component',
    defineAsyncComponent(() => import('./components/BugSubmissionModalComponent'))
);

app.component(
    'bug-submission-modal-btn-component',
    defineAsyncComponent(() => import('./components/BugSubmissionModalBtnComponent'))
);

app.component(
    'blogs-page-component',
    defineAsyncComponent(() => import('./components/BlogsPageComponent'))
);

app.component(
    'follow-up-wrapper-component',
    defineAsyncComponent(() => import('./components/FollowUpWrapperComponent'))
);

app.component(
    'group-page-component',
    defineAsyncComponent(() => import('./components/GroupPageComponent'))
);

app.mount('#root-app');
